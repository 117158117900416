.foobar {
  padding: 10px;
}

@font-face {
  src: url('fonts/Montserrat-VariableFont_wght.ttf');
  font-family: 'Montserrat';
}

@font-face {
  src: url('fonts/Changa-VariableFont_wght.ttf');
  font-family: 'Changa';
}

@font-face {
  src: url('fonts/Gabarito-VariableFont_wght.ttf');
  font-family: 'Gabarito';
}

$base-font-family: 'Gabarito', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

@import "minima";
  // "minima/skins/classic",
  // "minima/initialize";

h1, h2, h3, h4, h5 {
  font-family: 'Changa';
}

.site-title {
  font-family: 'Changa';
}

#sketch-holder {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

canvas {
  display: block;
}
